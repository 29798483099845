import React from "react"
import { Link } from "gatsby"
const data = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "about us",
    url: "/about/",
  },
  {
    id: 3,
    text: "services",
    url: "/services/",
  },
  // {
  //   id: 3,
  //   text: "projects",
  //   url: "/projects/",
  // },
  {
    id: 3,
    text: "digital university",
    url: "/digitalu/",
  },
  // {
  //   id: 4,
  //   text: "insights",
  //   url: "/insights/",
  // },
  {
    id: 4,
    text: "contact us",
    url: "/contact/",
  },
]

// const tempLinks = data.map(link => {
//   return (
//     <li key={link.id}>
//       <Link to={link.url}>{link.text}</Link>
//     </li>
//   )
// })

// export default ({ styleClass }) => {
//   return (
//     <ul className={`page-links ${styleClass ? styleClass : ""}`}>
//       {tempLinks}
//     </ul>
//   )
// }
export default data
