import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaGithubSquare, FaShareSquare } from "react-icons/fa"
import { Link } from "gatsby"
import data from "../constants/links"
const Service = ({
  description,
  title,
  icon,
  github,
  speciality,
  url,
  image,
  index,
  slug,
}) => {
  return (
    <article className="project">
      <GatsbyImage
        // image={image.localFile.childImageSharp.gatsbyImageData}
        image={image.localFile.childImageSharp.gatsbyImageData}
        // image={getImage(image)}
        className="project-img"
        alt={title}
      />
      <div className="project-info">
        {/* <span className="project-number">0{index + 1}.</span> */}
        <FaGithubSquare className="project-icon"></FaGithubSquare>
        <Link to={`/services/`} className="project-slug">
          <h3>{title}</h3>
        </Link>
        <p>{description}</p>
        <div className="project-stack">
          {speciality.map(item => {
            return <span key={item.id}>{item.detail}</span>
          })}
        </div>
        {/* <div className="project-links">
          <a href={github}>
            <FaGithubSquare className="project-icon"></FaGithubSquare>
          </a>
          <a href={url}>
            <FaShareSquare className="project-icon"></FaShareSquare>
          </a>
        </div> */}
      </div>
    </article>
  )
}

export default Service
