import React from "react"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import Services from "../components/Services"
import Seo from "../components/Seo"
import Layout from "../components/Layout"

// export const query = graphql`
//   {
//     allStrapiProject {
//       nodes {
//         description
//         feature
//         github
//         id
//         image {
//           localFile {
//             childImageSharp {
//               gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
//             }
//           }
//         }
//         stack_item {
//           detail
//           id
//         }
//         title
//         url
//       }
//     }
//   }
// `

export const query = graphql`
  {
    allStrapiService {
      nodes {
        description
        feature
        icon
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        slug
        speciality {
          detail
          id
        }
        title
      }
    }
  }
`

const ServicesPage = ({
  data: {
    allStrapiService: { nodes: services },
  },
}) => {
  return (
    <Layout>
      <Seo title="Services" desscription="This is our Services page" />
      <section className="projects-page">
        <Services services={services} title="all services" />
      </section>
    </Layout>
  )
}

export default ServicesPage
